/* Set global styles for HTML and BODY */
html {
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: 'Lato', 'Oxygen', 'Segoe UI', 'Calibri', -apple-system, BlinkMacSystemFont, 'Helvetica', 'Arial', 'sans-serif';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
body {
  color: #FFFFFF;
  margin: 0;
  padding: 0;
  background-color: #012F3E;
  height: 100vh;
  width: 100%;
  overflow-x: hidden;
  overflow-y: auto;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace, 'Apple System', BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
}

h1 {
  margin: 0px 5px 35px 5px;
}
h2 {
  margin: 5px 0px 20px 5px;
}
h3 {
  margin: 10px 5px 15px 5px;
}
h4 {
  margin: 10px 5px 5px 5px;
}
p {
  margin: 2px 5px 9px 5px;
  font-size: medium;
}
a {
  font-size: medium;
}
ul {
  margin: 4px 4px 8px 5px;
  list-style-type: square;
}
li {
  margin: 2px 4px 2px 5px;
  padding: 1px;
}
label {
  margin: 2px 5px 15px 5px;
}

/* Set specific background images for pages */
.binary-map-background {
  background-image: url('../Assets/binary-map.svg'), url('../Assets/bg-grad-01.svg');
  background-repeat: no-repeat, no-repeat;
  background-position: right 10% bottom 10%, center;
  background-size: 20%, stretch;
  background-size: auto 100%, cover;
}

/* Core content style below NavBar within the layout */
.layout-top-level {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  overflow-x: hidden;
  overflow-y: auto;
}
.content {
  flex-grow: 1;
  overflow-x: hidden;
  overflow-y: auto;
  min-height: calc(100vh - 55px);
}

/* Grid system styles */
.Grid {
  display:flex;
  flex-direction: column;
  overflow-x: hidden;
  min-height: calc(100vh - 55px);
  width: 100%;
  overflow-y: hidden;
}

.GridCellContainer {
  width: 100%;
  max-height: calc(100vh - 55px);
  padding: 0px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  overflow-x: hidden;
  overflow-y: hidden;
}

.GridCell {
  width: 100%;
  height: 100%;
  position: relative;
  max-height: 100%;
  padding: 0px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  overflow-x: hidden;
  overflow-y: hidden;
}

/* Navbar base style */
.navbar {
  position:relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background-color: #000515;
  box-shadow: 0 0 3px 5px #000515;
  height: 55px;
  padding: 0 10px;
  z-index: 10000;
}
.navbar-brand {
  display: flex;
  align-items: center;
}
.navbar-logo {
  height: 40px;
  padding: 7px 10px;
}

/* Navbar menu */
.navbar-menu-top-level {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  margin: 0px;
  padding: 0px;
  justify-content: space-between;
}

.navbar-menu {
  display: flex;
  gap: 0px;
  list-style-type: none;
  justify-content: space-between;
  margin: 0;
  padding: 0;
}
.navbar-menu li a {
  color: #FFFFFF;
  text-align: center;
  padding: 18px 24px;
  text-decoration: none;  
}
.navbar-menu li a:hover {
  background-color: #008B95;
  transition: background-color 0.4s ease-in-out;
  border-radius: 8px;
}
/* Identical for button within navbar as for 'a' link */
.navbar-menu li p{
  color: #FFFFFF;
  text-align: center;
  padding: 18px 24px;
  margin: 0px;
  text-decoration: none;
}
.navbar-menu li p:hover {
  background-color: #008B95;
  transition: background-color 0.4s ease-in-out;
  border-radius: 8px;
}


/* Menu icon style */
.menu-icon {
  display: none;
}




/* Navbar style adjustments for smaller screens */
@media screen and (max-width: 1200px) { /* Adjust breakpoint as needed */
  .Grid {
    flex-direction: column;
    overflow-y: auto; /* Allow y overflow on narrow screens */
  }

  .navbar {
    justify-content: space-between;
  }
  
  .navbar-menu {
    display: none;
  }

  .navbar-menu.visible {
    display: flex;
    flex-direction: column;
    min-height: 70px;
    align-items: center;
    width: 100%;
    position: absolute;
    top: 100%;
    left: 0;
    gap: 0px;
    list-style-type: none;
    margin: 0;
    padding: 0;
    background-color: #000515;
    z-index: 10001;
  }
  .navbar-menu.visible li {
    min-height: 45px;
    max-height: 60px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .menu-icon {
    display: block;
    color: #FFFFFF;
    background: #000515;
    border: none;
    cursor: pointer;
    padding: 4px 10px 4px 5px;
    font-size: xx-large;
  }
  .binary-map-background {
    background-image: url('../Assets/bg-grad-01.svg'); /* No binary map image just gradient background in narrow screen view */
    background-repeat: no-repeat;
    background-position: center;
    background-size: stretch;
    background-size: cover;
  }
}


/* Export-specific styles */
.export-pdf-style {
  background-color: #012F3E;
  font-size: 10pt;
  color: #FFFFFF; /* Double checks that text colour contrasts well with background for export */

  html, body {
    width: 1000px;
    height: 1469px;
    overflow: hidden; /* Prevent any content spillover */
    font-family: 'Arial', 'Verdana';
  }
  .content {
    height: 1214px; /* Exact without NavBar height gives expected 1414 for A4 vs 1000 width. Then adjusted further less header and footer */
    overflow: hidden;
  }
  .content-container {
    width: 100%;
    height: 100%;
  }
  .leaflet-container {
    min-height: 65vh;
  }
  .layout-top-level {
    width: 1000px;
    height: 1469px;
    display: block;
  }
  .Grid, .GridCell {
    min-height: auto; /* Removes min-height constraints */
    height: auto;
    overflow: visible; /* Allows visibility but within controlled dimensions */
  }

  /* Note that export doc header and footer currently below add to the document height with 80 + 180 = 260px */
  /* This leaves (from 1000w x 1414h A4 aspect ratio page) a remaining height of 1414 - 260 = 1154px height for actual content */
  .ExportDocHeader {
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-right: 20px; /* Adjust padding as necessary */
  }
  .ExportDocFooter {
      height: 180px;
      overflow-y: hidden;
      display: flex-start;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-left: 5px;
      margin-right: 5px;
      text-align: justify;
  }
  .ExportDocFooter p {
    font-size: 12px; /* Small print size */
}
  p {
    margin: 1px 3px 5px 3px;
  }
  h1 {
    font-size: 12pt;
  }
  h2 {
    font-size: 10pt;
  }
  h3, h4 {
    font-size: 10pt;
  }
  .content {
    width: 100%;
    overflow: visible;
  }
  .geography-type-dropdown h3 {
    margin-right: 0px;
  }
  .export-button, .form-button, .range-slider, .DateRangeSliderWrapper, .transparent-text-box, .XCloseButton, .tipsDropdown, .map-search, .tooltip-end-options, .modal-overlay, .modal-box {
    display: none;
  }
}