/* General component styles */
.XCloseButton {
    height: 33px;
    width: 33px;
    color:#FFFFFF;
    font-size: large;
    font-weight: bold;
    justify-content: center;
    align-items: center;
    margin-bottom: 2px;
    background-color: #012F3E;
    border-style: ridge;
    border-color:#008B95;
    border-radius: 4px;
    color: #FFFFFF;
    background: #022E3E;
    cursor: pointer;
    transition: background 0.2s ease-in-out;
    z-index: 200;
    text-align: center;
    justify-content: center;
}
.XCloseButton:hover {
    background: #008B95;
  }



/* Maps Styles */
.MapWindow {
    min-height: 100px;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }

/* Style for search box when collapsed*/
.map-search {
    position: absolute;
    top: 10px;
    right: 10px;
    margin-top: 10px;
    margin-right: 3px;
    margin-bottom: 10px;
    z-index: 1000; /* To ensure above the map */
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    width: 45px; /* Width of the inactive search bar */
    height: 45px; /* Width of the inactive search bar */    
    transition: transform 1s ease-in-out, box-shadow 1s ease-in-out;
    background: #ffffff;
    opacity: 0.8;
    border-radius: 6px;
    box-shadow: 0 0 5px rgba(0,0,0,0.5);
    overflow-x: hidden;
    overflow-y: hidden;
}
/* Style for search box when expanded*/
.map-search-active {
    position: absolute;
    top: 10px;
    right: 10px;
    margin-top: 10px;
    margin-right: 3px;
    margin-bottom: 10px;
    z-index: 1000; /* To ensure above the map */
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    min-width: 300px; /* Width of the expanded search bar */
    max-width: 300px; /* Maximum width to contain it within a reasonable size */
    min-height: 45px;
    max-height: 200px;
    transition: transform 1s ease-in-out, box-shadow 1s ease-in-out;
    background: #ffffff;
    opacity: 0.8;
    border-radius: 6px;
    box-shadow: 0 0 5px rgba(0,0,0,0.5);
    overflow-x: hidden;
    overflow-y: auto;
}

/* Style for the search icon */
.map-search-icon {
    background: url('/src/Assets/EL_Logo_RGB_CIRCLESONLY_MAGNIFYING_GLASS_Transparent.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: 35px;
    width: 38px; /* Icon size */
    height: 38px; /* Icon size */
    margin: auto; /* Center the icon */
    border: none;
    outline: none;
    cursor: pointer;
}

.map-search-input {
    display: none; /* Hidden by default */
}
.map-search-input-active {
    display: flex;
    flex-grow: 1; 
    align-items: center; /* Vertically center contents within the input */
    justify-content: left; /* Left align contents horizontally within the input */
    margin-left: 5px;
    margin-right: 5px;
    margin-bottom: 5px;
    padding: 6px 12px;
    border: 1px solid #ccc;
    border-radius: 4px;
    width: 88%; 
}

.map-search-results-list {
    display: none; /* Hidden by default */
}
.map-search-results-list-active {
    width: 96%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    margin-left: 5px;
    margin-right: 5px;
    margin-bottom: 5px;
}

.map-search-result {
    color: #141414;
    padding: 8px;
    width: 94%;
    border-bottom: 1px solid #ccc;
    cursor: pointer;
}
.map-search-result:last-child {
    border-bottom: none; /* No border for the last element */
}
.map-search-result:hover {
    background-color: #008B95;
    color: #ffffff;
}

.map-popup-contents {
    display: flex;
    flex-direction: column;
    align-items: left;
    justify-content: flex-start;
    width: 100%;
    height: auto;
    padding: 0px;
    background: #000515;
    border: none;
    color: #e2e2e2;
  }
.map-popup-area-contents {
    display: flex;
    flex-direction: row;
    align-items: left;
    justify-content: flex-start;
    width: 100%;
    height: auto;
    padding: 0px;
  }

.map-area-popup-stats {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    height: auto;
    padding: 0px;
  }
.map-area-popup-stats p {
    margin: 0px;
}


/* Export-specific styles for map controls */
.export-pdf-style {
    .leaflet-control-zoom-in, .leaflet-control-zoom-out, .map-search {
        display: none;
    }
}

/* Other elements use the below as an overlay (not conditional rendering) while full load condistions remain untrue*/
.loading-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(255, 255, 255, 0.95);
    z-index: 10000; /* Above the map or other background component that's loading */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.overlay-dark {
    background: rgba(1, 47, 62, 0.95);
}

.loading-overlay.fadeOut {
    animation: fadeOut 0.7s ease-out forwards;
  }
/* Fade out from semi-transparent to transparent */
@keyframes fadeOut {
from {
    opacity: 1;
}
to {
    opacity: 0;
}
}

/* leaflet map container */
.leaflet-container {
    min-height: 55vh;
    max-height: 100%;
    height: 100%;
    width: 100%;
    position: relative;
    padding: 0px;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    overflow-x: hidden;
    overflow-y: hidden;
}


/* Styles for map legend and sub parts */
.map-legend {
    position: absolute;
    bottom: 35px;
    left: 10px;
    padding: 10px; /* Space around the legend bar */
    z-index: 1000; /* To ensure above the map */
    background: #ffffff;
    box-shadow: 0 0 5px rgba(0,0,0,0.5);
    display: flex;
    flex-direction: row;
    align-items: center;
    border-radius: 6px;
    font-size: 15px;
    line-height: 24px;
    min-width: 50px;
    color: #555;
    }
.legend-key {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    height: 200px; /* Matches the height of the colour bar */
    }
.legend-key span {
    display: block;
    margin-bottom: 4px; 
}

.info {
    padding: 6px 8px;
    font: 14px/16px Arial, Helvetica, sans-serif;
    background: rgba(255,255,255,0.8);
    box-shadow: 0 0 15px rgba(0,0,0,0.2);
    border-radius: 5px;
}




/* Charts Styles */
.ChartWindow {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }
.recharts-responsive-container {
    min-height: 40vh;
    max-height: 95%;
    max-width: 90%;
    position: relative;
    padding: 0px;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    overflow-x: hidden;
    overflow-y: hidden;
    margin: 10px;
}
.recharts-cartesian-axis-tick {
    overflow: visible;
    white-space: normal;
}
.recharts-cartesian-axis-tick-value {
    transform: translateY(6px);
    transform: translateX(3px);
}

.ResponsiveContainerWrapper {
    width: 100%;
    flex-grow: 1;
    overflow: hidden;
    display: flex;
}
.chart-and-slider {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
}
.DateRangeSliderWrapper {
    width: 100%;
    height: 60px;
    margin-bottom: 10px;
    overflow: hidden;
}

.range-slider {
    height: 100%;
    width: 80%;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
    margin-left: 10%;
    margin-right: 10%;
    margin-bottom: 50px;
}
@media screen and (max-width: 1200px) {
    .range-slider {
        margin-left: 15%;
        width: 70%; /*Slider is less wide on mobile to avoid back action interference*/
    }
}
.slider-rail {
    position: absolute;
    width: 100%;
    height: 12px;
    border-radius: 5px;
    background-color: #ccc;
}
.slider-handle {
    position: absolute;
    margin-left: -11px;
    margin-top: -6px;
    z-index: 2;
    width: 20px;
    height: 20px;
    border: 2px solid #333;
    background-color: #FFF;
    border-radius: 50%;
    cursor: pointer;
}
.slider-track {
    position: absolute;
    height: 12px;
    z-index: 1;
    background-color: #008B95;
    border-radius: 5px;
    cursor: pointer;
}


.custom-tooltip {
    z-index: 2000;
    width: auto;
    min-width: 150px;
    max-width: 200px;
    height: auto;
    max-height: 50%;
    margin: 5px;
    padding: 10px;
    background-color: #012F3E;
    opacity: 90%;
    border: 1px solid #ccc;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0,0,0,0.1);
    text-align: left;
    white-space: normal;
    word-wrap: break-word;
    animation: fadeIn 1s;
}
@keyframes fadeIn {
    0% { opacity: 0; }
    25% { opacity: 0; }
    100% { opacity: 1; }
  }
.custom-tooltip p{
    margin-bottom: 5px;
    padding: 0px;
    font-size: 13px;
}
.export-pdf-style {
    .custom-tooltip {
        animation: none;
    }
}
/* Reports Styles */
.location-report-title {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
}
.location-report-title h2{
    font-size: xx-large;
    margin-top: 14px;
    margin-bottom: 6px;
    z-index: 9000;
}

.comparator {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    margin-top: 10px;
    margin-bottom: 10px;
    min-width: 120px;
    width: 95%;
    max-width: 400px;
    min-height: 150px;
    max-height: 100%;
    border-radius: 8px;
    border: 1px solid #ccc;
    box-shadow: 0px 4px 8px #ccc;
    background-color: #000515;
    overflow-x: hidden;
    overflow-y: auto;
}
@media screen and (max-width: 1200px) {
    .comparator {
        min-height: 50px;
    }
}
.comparator p {
    margin: 2px;
    padding: 2px;
    font-size: 18px;
}
.comparator h2 {
    margin: 2px;
    padding: 5px;
}

.comparator-primary-and-metric-wrapper {
    display: flex;
    flex-direction: row;
    flex-grow: 1; /*Allow the container to grow*/
    justify-content: stretch;
    align-items: center;
    text-align: center;
    width: 96%;
    min-height: 120px;
    height: auto;
    padding: 0px;
    margin: 2px;
    border-style: solid;
    border-width: 3px;
    border-color:#008B95;
    opacity: 100%;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.05);
}
@media screen and (max-width: 1200px) {
    .comparator-primary-and-metric-wrapper  {
        min-height: 50px;
    }
}
.comparator-plus {
    border-style:dashed;
    border-width: 3px;
    background-color: #012F3E;
    box-shadow: none;
}
.comparator-primary-and-metric-wrapper h2{
    font-size: xx-large;
    margin-top: 2px;
    margin-bottom: 6px;
    white-space: normal; /* Allow wrapping */
}
.comparator-selector-and-first-location-wrapper {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    height: auto;
    padding: 5px;
    margin: 0px;
}

.comparator-stat-and-first-location {
    display: flex;
    flex-direction: row;
    justify-content:flex-start;
    align-items: center;
    text-align: center;
    min-width: 50px;
    height: auto;
    padding: 0px;
    margin: 0px;
    opacity: 100%;
    border-radius: 8px;
    border:'none';
    box-shadow: 'none';
}
.comparator-stat-and-first-location p{
    display: flex;
    flex-direction: row;
    justify-content:flex-start;
    align-items: flex-start;
    text-align: left;
    height: auto;
    padding: 0px;
    margin: 0px;
}

.comparator-stat-and-location {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    text-align: center;
    width: 97%;
    height: auto;
    padding: 1px;
    margin: 0px 0px 4px 1px;
    opacity: 100%;
    border-radius: 8px;
    border:'none';
    box-shadow: 'none';

    flex-direction: row;
    justify-content:flex-start;
    align-items: center;
    /* Note background colour set in js by conditional colour settings logic */
}
.comparator-stat-and-location p{
    display: flex;
    flex-direction: row;
    justify-content:flex-start;
    align-items: flex-start;
    text-align: left;
    padding-top: 2px;
    padding-bottom: 2px;
    margin-top: 5px;
    margin-bottom: 5px;
}

.comparator-value {
    min-width: 50px;
    margin-left: 20px;
    text-align: center;
}
.comparator-location-label {
    margin-left: 5px;
    margin-top: 0px;
    text-align: center;
}
.comparator-value p{
    margin: 0px;
    font-size: 15px;
}

.comparator-location-label p {
    margin: 0px;    
    font-size: 15px;
}

/* Tips dropdown styles*/
.tipsDropdown {
    height: auto;
    width: 85%;
    max-width: 500px;
    box-sizing: border-box;
    background: #022E3E;
    border-style: solid;
    border-color: #008B95;
    border-width: 2px;
    border-radius: 7px;
    margin-top: 5px;
    margin-bottom: 2px;
    margin-left: 0px;
    margin-right: 0px;
    overflow-y: auto;
    transition: background 0.2s ease-in-out;
    overflow: hidden;

}
.tipsDropdown:hover {
    border-width: 4px;
}

.tipsDropdown .dropdownTitleSection {
    min-width: 100px;
    width: 100%;
    min-height: 24px;
    height: auto;
    overflow-y: auto;
    max-height: 68px;
    padding: 0px;
    margin: 5px 0px 5px 0px;
    color: #FFFFFF;
    background: #022E3E;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border: none;
}
.tipsDropdown .dropdownTitleSection p {
    padding: 2px;
    padding-top: 6px;
    margin: 2px;
    color: #FFFFFF;
}
.tipsDropdown .dropdownArrow {
    text-align: left;
    color: #ccc;
    margin: 0px;
    margin-right: 10px;
    padding: 0px;
    font-size: 10px;
}
.tipsDropdown .dropdownTitle {
    margin-left: 10px;
    margin-right: 10px;
    text-align: left;
}
.tipsDropdown .dropdownContent {
    min-width: 100px;
    width: 100%;
    max-width: 100%;
    background: rgba(1, 47, 62, 0.5);
    transition: max-height 0.5s ease-in-out;
    cursor: default;
    overflow-x: hidden;
    overflow-y: hidden;
    border: none;
    border-radius: 7px;
    text-align: left;
}
  
.tipsDropdown .dropdownContent p {
    margin: 10px 0;
    color: #ccc;
    font-size: medium;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 10px;
}

/* Social Sharing Styles */
.social-media-share-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 2px;
    margin-bottom: 2px;
    width: 100%;
}
.social-media-share-banner {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 90%;
    max-width: 350px;
}
.social-share-button {
    width: 50px;
    height: 50px;
    border-width: 2px;
    border-radius: 8px;
    border-color:#008B95;
    border-style: solid;
    cursor: pointer;
    padding: 0;
    background: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px;
}
.social-share-button:hover {
    border-width: 5px;
}
.social-share-image {
    margin: 2px;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }