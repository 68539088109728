.MatrixStream {
    position: absolute;
    display: flex;
    flex-direction: column; /* Ensure characters stack vertically */
    will-change: transform; /* Hint to the browser to optimize for transform changes */
}

.MatrixCharacter {
    color: #73C8CE;
    font-family: 'Courier New', Courier, monospace;
    white-space: nowrap;
}

.MatrixCharacter0 {
    color: #008B95; /* Example color for 0 */
}

.MatrixCharacter1 {
    color: #73C8CE; /* Example color for 1 */
    font-weight: 600;
}

.MatrixContainer {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    z-index: -1; /* Ensure it stays in the background */
}

.MatrixBackgroundGradient {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #000515;
    animation: backgroundFade 40s;
}

@keyframes backgroundFade {
    0%, 20%, 100% {
        background-color: #000515; /* Start color */
    }
    40% {
        background-color: #012F3E;
    }
    55%, 75% {
        background-color: #0096A0; /* End color */
    }
    90% {
        background-color: #012F3E; /* End color */
    }
}