.loader {
    position: relative;
    min-width: 100px;
    min-height: 100px;
    width: 100%;
    height: 100%;
    overflow: hidden;
    z-index: 10; /* Adjust as needed */
    margin: 0;
}
.export-pdf-style {
    .loader {
        position: absolute;
        min-width: 100px;
        min-height: 100px;
        width: 100%;
        height: 200%;
        overflow: hidden;
        z-index: 999999; /* Adjust as needed */
        margin: 0;
        background-color: #022E3E;
    }
}

.loader-inner {
    position: absolute;
    top: 50%; /* Note this is adapted dynamically in js with top: isExportVersion ? '25%' : '50%'*/
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100px;
    height: 100px;
}

.loader-line-wrap {
    animation: 
		spin 2200ms cubic-bezier(0.3, .5, .3, .2) infinite
	;
    box-sizing: border-box;
    height: 50px;
    left: 0;
    overflow: hidden;
    position: absolute;
    top: 0;
    transform-origin: 50% 100%;
    width: 100px;
}
.loader-line {
    border: 4px solid transparent;
    border-radius: 100%;
    box-sizing: border-box;
    height: 100px;
    left: 0;
    margin: 0 auto;
    position: absolute;
    right: 0;
    top: 0;
    width: 100px;
}
.loader-line-wrap:nth-child(1) { animation-delay: -250ms; }
.loader-line-wrap:nth-child(2) { animation-delay: -350ms; }
.loader-line-wrap:nth-child(3) { animation-delay: -450ms; }
.loader-line-wrap:nth-child(4) { animation-delay: -550ms; }
.loader-line-wrap:nth-child(5) { animation-delay: -650ms; }

.loader-line-wrap:nth-child(1) .loader-line {
    border-color: #022E3E;
    height: 90px;
    width: 90px;
    top: 7px;
}
.loader-line-wrap:nth-child(2) .loader-line {
    border-color: #008B95;
    height: 76px;
    width: 76px;
    top: 14px;
}
.loader-line-wrap:nth-child(3) .loader-line {
    border-color: #73C8CE;
    height: 62px;
    width: 62px;
    top: 21px;
}
.loader-line-wrap:nth-child(4) .loader-line {
    border-color: #008B95;
    height: 48px;
    width: 48px;
    top: 28px;
}
.loader-line-wrap:nth-child(5) .loader-line {
    border-color: #022E3E;
    height: 34px;
    width: 34px;
    top: 35px;
}

@keyframes spin {
    0%, 50% {
		transform: rotate(90);
	}
	100% {
		transform: rotate(720deg);
	}
}