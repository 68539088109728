.content-container {
  width: 100%;
  height: 100%;
  padding: 0px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  overflow-x: hidden;
  overflow-y: hidden;
}


/* Modal overlay styles */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.75);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9000000;
  overflow-y: auto;
}
.modal-box {
  position: relative;
  width: 80%;
  max-width: 350px;
  margin: 10px;
  padding: 15px;
  border-radius: 8px;
  box-shadow: 0px 4px 10px #008B95;
  background: #000515;
  color: #e2e2e2;
  align-items: left;
  justify-content: flex-start;
  overflow-y: auto;
}
.modal-title {
  margin-bottom: 5px;
  margin-right: 8px;
}
.modal-title h2 {
  margin-bottom: 5px;
}
.modal-content {
  margin-top: 10px;
  align-items: center;
  justify-content: center;
}
/* end of modal styles  */

.form-container {
  width: 90%;
  height: 100%;
  position: relative;
  display:  flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.top-start {
  justify-content: left;
}
.left-align {
  align-items: start;
}
.yscroll-allowed {
  overflow-y: auto;
}
.menu-title-container-offset {
  margin-top: 20px;
  margin-left: 35px;
  margin-bottom: 10px;
}
@media screen and (max-width: 1200px) {
  .menu-title-container-offset {
    margin-top: 5px;
    margin-bottom: 5px;
  }
}


.transparent-text-box {
  flex-direction: column;
  min-width: 350px;
  width: 80%;
  max-width: 600px;
  height: auto;
  max-height: 100%;
  padding: 10px;
  margin: 30px 5px 5px 5px;
  display: flex;
  align-items: left;
  justify-content: flex-start;
  color: #e2e2e2;
}
.pricing-info p{
  margin-bottom: 1px;
}
.pricing-subtext p {
  margin-top: 5px;
  font-size: 11px;
}
.pricing-footer p {
  font-size: 12px;
}



.form-box {
  flex-direction: column;
  min-width: 350px;
  width: 80%;
  max-width: 600px;
  height: auto;
  padding: 10px;
  margin: 30px 5px 5px 5px;
  background: #000515;
  border-radius: 8px;
  box-shadow: 0px 4px 10px #008B95;
  display: flex;
  align-items: left;
  justify-content: flex-start;
  color: #e2e2e2;
}
/* Slight adjustments for smaller screens */
@media screen and (max-width: 1400px) { /* Non standard breakpoint here to best fit page */
  .form-box  {
    min-width: 330px;
  }
}

.form-box-table-container {
  display: flex;
  justify-content: flex-start;
  overflow-x: auto;
  flex-wrap: wrap;
}
.form-box-table-first-column {
  min-width: 10px; /* Adjust this value to set the minimum width */
  flex: 1;
  margin: 0 0px;
  max-width: 30%;
  overflow-wrap: break-word;
}
.form-box-table-item {
  min-width: 10px; /* Adjust this value to set the minimum width */
  flex: 2;
  margin: 0 0px;
  max-width: 70%;
  overflow-wrap: break-word;
}


.form-plaintext-box {
  flex-direction: column;
  min-width: 250px;
  max-width: 500px;
  min-height: 100px;
  max-height: 500px;
  border-radius: 8px;
  padding: 10px;
  color: #7A7A7A;
  background-color: #000515;
  display: flex;
  justify-content: flex-start;
  align-items: left;
  overflow-y: scroll; 
}


.form-input {
  width: 93%;
  padding: 10px;
  margin-top: 5px;
  margin-bottom: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background: #FFFFFF;
  color: #4F4F4F;
}

.form-password-input {
  display: block;
  width: 93%;
  padding: 10px;
  margin-top: 5px;
  margin-bottom: 6px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background: #FFFFFF;
  color: #4F4F4F;
}

.install-button {
  min-width: 100px;
  width: 85%;
  max-width: 500px;
  box-sizing: border-box;
  height: 45px;
  border-style: solid;
  border-color:#008B95;
  border-width: 2px;
  border-radius: 7px;
  color: #FFFFFF;
  background: #022E3E;
  cursor: pointer;
  transition: background 0.2s ease-in-out;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0px;
  margin: 0px;
}
.install-button:hover {
  border-width: 4px;
}

.form-button {
  min-width: 100px;
  width: 100%;
  box-sizing: border-box;
  height: 34px;
  padding: 1px;
  margin: 5px 0px 5px 0px;
  align-items: center;
  justify-content: center;
  text-align: center;
  border-style: solid;
  border-color:#008B95;
  border-width: 2px;
  border-radius: 7px;
  color: #FFFFFF;
  background: #022E3E;
  cursor: pointer;
  transition: background 0.2s ease-in-out;
}
.form-button p {
  margin: 5px;
  font-size: medium;
}
.form-button:hover {
  border-width: 4px;
}
.muted-border {
  border-color: #335864;
}
.form-button.muted-border:hover {
  border-width: 3px;
  border-color: #008B95;
}
/* Register button specific styles */
.form-button.register-button {
  margin-top: 15px;
  width: 97%;
  background-color: rgba(8, 15, 35, 0.3);
  color: rgba(200, 200, 200, 0.8);
  border-color: rgba(8, 15, 35, 0.1);
  box-shadow: 0px 4px 10px rgba(0, 139, 149, 0.5);
}
.form-button.register-button:hover {
  border-width: 3px;
  border-color: #008b95;
  background-color: #022E3E;
  color: #FFFFFF;
}

.export-share-button {
  width: 95%;
  max-width: 400px;
  height: 64px;
  padding: 1px;
  margin: 5px 0px 2px 0px;
  border-width: 2px;
  border-radius: 8px;
  border-color:#008B95;
  border-style: solid;
  color: #FFFFFF;
  padding: 0;
  background: none;
  cursor: pointer;
  transition: background 0.2s ease-in-out;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;

}
.export-share-button:hover {
  border-width: 5px;
}


.dropdown-condensed-box {
  flex-direction: column;
  width: 100%;
  height: auto;
  padding: 0px;
  margin: 0px;
  margin-right: 30px;
  background: #012F3E;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: flex-start;
  color: #e2e2e2;
  z-index: 9000;
}
.dropdown {
  width: 95%;
  margin: 5px;
  border-color: #008B95;
  border-width: 2px;
  border-radius: 7px;
  background: #022E3E;
  color: #FFFFFF;
}
.geography-type-dropdown h3 {
  text-align: center;
  font-size: 22px;
  margin-right: 40px;
}

/* Special styles for error boxes */
.error-box {
  width: 100%;
  padding: 1px;
  margin: 5px 0px 5px 0px;
  border: none;
  border-radius: 4px;
  color: #FAFAFA;
  background: #3B0000;
  align-items: center;
  justify-content: center;
}
.error-message {
  font-weight: bold;
  padding: 8px;
  margin: 0px;
  border: 0px;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 1em;
}


/* Special styles for access denied page */
.access-denied {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: top;
  height: 100vh;
  background-color: #3B0000;
  color: #4C0805;
  font-family: monospace;
}
.access-denied h1 {
  margin-bottom: 20px;
  color: #FAFAFA;
  font-size: 3em;
}
.access-denied p {
  color: #FAFAFA;
  font-size: 1.2em;
}


/* Styles for T&Cs etc sepcial styles */
.version-footer {
  height: auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: bottom;
  text-align: right;
  margin-bottom: 10px;
}
.version-footer p {
  font-size: 11px;
  margin-right: 15px;
  margin-bottom: 2px;
}

/* Styles to support fade in effects for certain forms e.g. login form */
@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}
.fadeInEffect {
  opacity: 0; /* This ensures the element starts as invisible */
  animation: fadeIn 1.25s ease-in-out forwards;
  animation-delay: 0.5s;
}
.fadeInEffectDelayed {
  opacity: 0; /* This ensures the element starts as invisible */
  animation: fadeIn 1.25s ease-in-out forwards;
  animation-delay: 3.5s;
}